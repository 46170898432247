import React from 'react';

import { SkillProps } from './types';
import { SkillContainer, SkillLabel, SkillBarContainer, SkillBar } from './styled';

const Skill = ({ skill }: SkillProps) => {
	return (
		<SkillContainer>
			<SkillLabel>{skill.name}</SkillLabel>
			<SkillBarContainer>
				<SkillBar skillLevel={skill.level} />
			</SkillBarContainer>
		</SkillContainer>
	);
};

export default Skill;