import styled from 'styled-components';

export const HobbyList = styled.ul`
	margin: 0;
	padding-left: 30px;

	@media (max-width: 900px) {
		padding-left: 0;
	}
`;

export const HobbyEntry = styled.li`
	font-size: 18px;
	padding-bottom: 5px;
`;
