import React, { ReactElement } from 'react';

import { HeaderProps } from './types';
import { HeaderContainer, HeaderName, HeaderTitle, ProfileImage, HeaderTextContainer, HeaderIdentifiers } from './styled';

const Header = ({ name, title, profileImageSrc, identifiers }: HeaderProps): ReactElement => {
	return (
		<HeaderContainer>
			<ProfileImage alt="headshot" src={profileImageSrc} />
			<HeaderTextContainer>
				<HeaderName>{name}</HeaderName>
				<HeaderTitle>{title}</HeaderTitle>
				{!!identifiers && <HeaderIdentifiers>{identifiers}</HeaderIdentifiers>}
			</HeaderTextContainer>
		</HeaderContainer>
	);
};

export default Header;