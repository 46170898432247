import React from 'react';

import Section from 'components/Section';

import { HobbiesProps } from './types';
import { HobbyList, HobbyEntry } from './styled';

const Hobbies = ({ hobbies }: HobbiesProps) => {
	return <Section
		title={
			<>
				<i className="fas fa-gamepad" />
				<span>Hobbyer</span>
			</>
		}
		sideBar
	>
		<HobbyList>
			{hobbies.map((hobby, i) => <HobbyEntry key={`hobby-${i}`}>{hobby}</HobbyEntry>)}
		</HobbyList>
	</Section>;
};

export default Hobbies;
