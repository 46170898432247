import React from 'react';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FooterContainer, FooterLink, AboutLinksContainer, PortfolioSite } from './styled';
import { FooterProps, TAboutLink } from './types';

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const Footer = ({ portfolioSite, aboutLinks }: FooterProps) => {
	return (
		<FooterContainer>
			<AboutLinksContainer>
				{aboutLinks.map((link: TAboutLink, index: number) =>
					<FooterLink
						key={index}
						href={link.url} 
						colorHex={link.colorHex}
						target="_blank"
						rel="noopener noreferrer"
						title={link.hoverText}
					>
						<i className={link.iconClass}></i>
					</FooterLink>
				)}
			</AboutLinksContainer>
			{/* <PortfolioSite>
        <FooterLink
          primary
          href={portfolioSite.url}
          colorHex={portfolioSite.colorHex}
          target="_blank"
          rel="noopener noreferrer"
          title={portfolioSite.hoverText}
        >
          {portfolioSite.text}
        </FooterLink>
      </PortfolioSite> */}
		</FooterContainer>
	);
};

export default Footer;
