export const skills = [
	{name: 'JavaScript', level: 3},
	{name: 'TypeScript', level: 3},
	{name: 'ReactJS', level: 3},
	{name: 'HTML', level: 3},
	{name: 'CSS', level: 3},
	{name: 'NodeJS', level: 2},
	{name: 'Python', level: 1},
	{name: 'Java', level: 1},
	{name: 'C#', level: 1},
	{name: 'Django', level: 1},
	{name: 'Nerd', level: 4}
];
