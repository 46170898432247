export const aboutLinks = [
	{
		url: 'https://github.com/DSandberg93',
		iconClass: 'fab fa-github',
		hoverText: 'My GitHub',
		colorHex: '#8a2494'
	},
	{
		url: 'https://www.linkedin.com/in/dsandberg93/',
		iconClass: 'fab fa-linkedin',
		hoverText: 'My LinkedIn',
		colorHex: '#0077b5'
	},
];

export const portfolioSite = {
	url: 'https://WebDSandberg.com/',
	hoverText: 'My Portfolio Website',
	text: 'WebDSandberg.com',
	colorHex: '#0A0'
};
