// import React from 'react';
import { TCharacteristic, TResume } from './types';
import { getAge } from './age';
import { skills } from './skills';
import { aboutLinks, portfolioSite } from './links';

const about = {
	intro: 'Hi, I\'m Daniel Sandberg',
	description: `I'm a ${getAge()} year old nerd from Melhus in Central Norway. I'm currently working on a Master's Degree in Computer Science, specializing in software development, at the Norwegian University of Science and Technology in Trondheim. In my spare time I build websites, play computer games and play music, among other things.`
};

// const accomplishments = [
//   {
//     title: '1 of 3 Winners of the 16th Monthly Coding Challenge',
//     entry: 'My personal portfolio website, WebDSandberg.com',
//     entryLink: 'webdsandberg.com',
//     entryUrl: 'https://webdsandberg.com/v2',
//     repo: false,
//     bestower: <span><a href='https://github.com/aneagoie' target='_blank' rel='noopener noreferrer'>Andrei Neagoie</a> and representatives from <a href='https://www.creative-tim.com/' target='_blank' rel='noopener noreferrer'>Creative Tim</a></span>,
//     description: <span>The challenge was to build a beautiful single-page website using modern design techniques and on of the free templates from Creative Tim. Included in the challenge were also 3 resources the contestants were supposed to use. I chose to use an existing portfolio website that I had, which already used one of the free templates, and give it a serious facelift. For a more detailed description of the challenge, checkout <a href='https://github.com/zero-to-mastery/coding_challenge-16' target='_blank' rel='noopener noreferrer'>github.com/zero-to-mastery/coding_challenge-16</a>.</span>
//   }
// ];

const education = [
	{
		studyProgram: 'Master\'s Degree in Computer Science',
		school: 'Norwegian University of Science and Technology (NTNU)',
		startDate: 'August 2015',
		graduationDate: 'Not yet (June 2020)',
		summary: 'I’m currently studying for a master’s degree in Computer Science at the Norwegian University of Science and Technology (NTNU), specializing in software development. During my 4 years at the university, I’ve taken part in various projects, such as designing and developing a game for Android phones and creating a forum bot to support the process of Q&A on Piazza.com, and learned about a variety of subjects and concepts, such as software architecture, software development and basic algorithms for introductory artificial intelligence.'
	},
	{
		studyProgram: 'One-year study in Leadership',
		school: 'Bildøy Bible College, Bergen',
		startDate: 'August 2013',
		graduationDate: 'June 2014',
		summary: 'I studied leadership at Bildøy Bible College for a year. During this year I worked as a trainee at Betlehem, where I was given experience in the various parts of leadership in a congregation. As part of the study, I took part in lectures about different subjects relevant to be a leader.'
	},
	{
		studyProgram: 'One-year study in Music and the Bible',
		school: 'Bildøy Bible College, Bergen',
		startDate: 'August 2012',
		graduationDate: 'June 2013',
		summary: 'I studied music and the Bible for a year at Bildøy Bible College, a boarding school just outside Bergen. During the music portion of the year I studied theoretical subjects such as music history and basic music theory, received tutoring in drums and the acoustic guitar, and practiced my musical talents in in-house concerts. The Bible portion of the year was focused on obtaining surface knowledge and a basic understanding of the Old and the New Testaments, as well as more in-depth studies of few chosen parts of the Bible.'
	}
];

const employmentHistory = [
	{
		employer: 'Tise AS',
		position: 'Web Developer',
		startDate: 'July 1st 2020',
		endDate: 'Present',
		summary: ''
	},
	{
		employer: 'Tise AS',
		position: 'Part-time Web Developer',
		startDate: 'August 2019',
		endDate: 'June 30th 2020',
		summary: ''
	},
	{
		employer: 'Tise AS',
		position: 'Web Summer Intern',
		startDate: 'June 2019',
		endDate: 'August 2019',
		summary: ''
	},
	{
		employer: 'Visma International AS',
		position: 'Summer Intern',
		startDate: 'June 18th 2018',
		endDate: 'August 7th 2018',
		summary: 'During my internship, I worked on e2e testing of an AngularJS web application using Protractor. I created tests for automatic testing of the UI of the web application.'
	},
	{
		employer: 'Norstat Trondheim',
		position: 'Phone Interviewer',
		startDate: 'October 19th 2016',
		endDate: 'January 6th 2017',
		summary: 'My work as a phone interviewer consisted of asking people questions regarding advertising, politics, store sales, and various other subjects.'
	},
	{
		employer: 'Vivo Trondheim',
		position: 'Part-time Employee',
		startDate: 'November 1st 2014',
		endDate: 'August 2nd 2015',
		summary: 'As a part-time employee at the bookstore Vivo Trondheim, my responsibilities were customer service, sales and shelving of books, receipt of goods, and basic cleaning of the store.'
	},
	{
		employer: 'Bildøy Bible College, Bergen',
		position: 'PR-Employee',
		startDate: 'July 1st 2014',
		endDate: 'August 10th 2014',
		summary: 'During my summer job as PR-employee for Bildøy Bible College, I toured 5 different summer youth conventions and advertised on behalf of the school, talking to the convention participants about my experience from and their opportunities at the school.'
	},
	{
		employer: 'Betlehem, Bergen IM',
		position: 'Trainee',
		startDate: 'August 12th 2013',
		endDate: 'June 13th 2014',
		summary: 'As part of my year as a trainee at Betlehem, Bergen IM (a congregation in Bergen, Norway), I worked as part of the leadership team for the youth and student work, both within the congregation itself and another congregation connected to it.'
	}
];

const identifiers = 'Nerd, Christian, Software Developer, Gamer, Musician';

const profileName = 'Daniel Sandberg';

const profileTitle = 'Software Developer';

const hobbies = ['Developing websites, playing games, reading books, playing and listening to music, and drawing'];

const characteristics: TCharacteristic[] = [];

const resume: TResume = {
	skills,
	hobbies,
	characteristics,
	identifiers,
	profileName,
	profileTitle,
	employmentHistory,
	about,
	aboutLinks,
	portfolioSite,
	// accomplishments,
	education,
};

export default resume;
