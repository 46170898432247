import React, { ReactElement } from 'react';

import { EducationHistoryProps } from './types';
import { TEducation } from './Education/types';

import Education from './Education';
import Section from 'components/Section';

const EducationHistory = ({ educationHistory }: EducationHistoryProps): ReactElement => {
	return (
		<Section
			title={
				<>
					<i className="fas fa-graduation-cap" />
					<span>Utdanning</span>
				</>
			}
			isList
		>
			{educationHistory.map((education: TEducation, index: number) => <Education key={index} education={education} />)}
		</Section>
	);
};

export default EducationHistory;
