import styled, { css } from 'styled-components';

import { FooterLinkProps } from './types';

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  text-align: center;
  grid-area: footer;
  flex-direction: column;
  margin: 40px auto 0;
  border-top: 1px solid lightgrey;
  padding: 40px 0;
`;

export const FooterLink = styled.a<FooterLinkProps>`
  color: black;
  text-decoration: none;
  margin: 5px;
  
  &:hover {
    ${(props) => props.colorHex && css`
      color: ${props.colorHex};
    `}
  }
`;

export const AboutLinksContainer = styled.div`
  margin: 0.5em auto;
  font-size: 28px;

  @media (max-width: 500px) {
    font-size: 24px;
  }
`;

export const PortfolioSite = styled.div`
  margin: 0.5em auto;
  width: fit-content;
  font-size: 40px;

  @media (max-width: 500px) {
    font-size: 32px;
  }
`;
