import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 125px auto;
  grid-template-areas: "profile-image header-text";
  grid-gap: 20px;
  grid-area: header;

  @media (max-width: 500px) {
    grid-template-columns: auto;
    grid-template-areas: "profile-image" "header-text";
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const HeaderName = styled.h1`
  font-size: 40px;
  font-weight: 500;
  margin: 0;
`;

export const HeaderTitle = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: #444;
  margin: 0;
`;

export const HeaderIdentifiers = styled.h3`
  margin: 5px 0 0 0;
  font-size: 14px;
  color: #777;
  font-style: italic;
  font-weight: 500;
`;

export const ProfileImage = styled.img`
  border-radius: 50%;
  width: 100%;
  grid-area: profile-image;
  margin: auto;
  max-width: 150px;
`;

export const HeaderTextContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;
