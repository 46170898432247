import React from 'react';

import { SectionProps } from './types';
import { SectionContainer, SectionBody, SectionTitle } from './styled';

const Section: React.FC<SectionProps> = ({ title, children, isList, sideBar, skills }) => {
	return (
		<SectionContainer sideBar={sideBar}>
			<SectionTitle sideBar={sideBar}>{title}</SectionTitle>
			<SectionBody isList={isList} sideBar={sideBar} skills={skills}>
				{children}
			</SectionBody>
		</SectionContainer>
	);
};

export default Section;
