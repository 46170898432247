import React, { ReactElement } from 'react';

import { SectionEntryContainer, SectionEntryTitle, SectionEntryPeriod, SectionEntrySummary } from 'components/Section/styled';

import { EducationProps } from './types';

const Education = ({ education }: EducationProps): ReactElement => {
	return (
		<SectionEntryContainer>
			<SectionEntryTitle>
				<span>{education.studyProgram}</span> ved <span>{education.school}</span>
			</SectionEntryTitle>
			<SectionEntryPeriod>
				<span>{education.startDate}</span> - <span>{education.graduationDate}</span>
			</SectionEntryPeriod>
			<SectionEntrySummary>
				{education.summary}
			</SectionEntrySummary>
		</SectionEntryContainer>
	);
};

export default Education;
