import React from 'react';

import profileImage from 'images/profile_image.jpg';
import Header from 'containers/Header';
import EmploymentHistory from 'containers/EmploymentHistory';
import EducationHistory from 'containers/EducationHistory';
import Skills from 'containers/Skills';
import Footer from 'containers/Footer';
import Hobbies from 'containers/Hobbies';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { en, nb } from 'translations';

import { Container,
	Main,
	AdditionalInfo
} from './styled';

const App: React.FC = () => {
	const resume = nb;
	return (
		<Container>
			<Header name={resume.profileName} title={resume.profileTitle} profileImageSrc={profileImage} identifiers={resume.identifiers} />
			<Main>
				<EmploymentHistory employmentHistory={resume.employmentHistory} />
				<EducationHistory educationHistory={resume.education} />
			</Main>
			<AdditionalInfo>
				<Skills skills={resume.skills} />
				<Hobbies hobbies={resume.hobbies} />
			</AdditionalInfo>
			<Footer portfolioSite={resume.portfolioSite} aboutLinks={resume.aboutLinks} />
		</Container>
	);
};

export default App;
