import React, { ReactElement } from 'react';

import { SectionEntryContainer, SectionEntryTitle, SectionEntryPeriod, SectionEntrySummary } from 'components/Section/styled';

import { EmploymentProps } from './types';

const Employment = ({employment}: EmploymentProps): ReactElement => {
	return (
		<SectionEntryContainer>
			<SectionEntryTitle>
				<span>{employment.position}</span> hos <span>{employment.employer}</span>
			</SectionEntryTitle>
			<SectionEntryPeriod>
				<span>{employment.startDate}</span> - <span>{employment.endDate}</span>
			</SectionEntryPeriod>
			<SectionEntrySummary>
				{employment.summary}
			</SectionEntrySummary>
		</SectionEntryContainer>
	);
};

export default Employment;
