import styled, { css } from 'styled-components';
import { SectionBodyProps, SectionContainerProps, SectionTitleProps } from './types';

export const SectionContainer = styled.div<SectionContainerProps>`
  display: grid;
  grid-template-columns: 40px auto;
  grid-template-areas: "section-title section-title" ". section-body";
  grid-gap: 10px;

  @media (min-width: 900px) {
    grid-template-columns: ${({ sideBar }) => sideBar && '100%'};
    grid-template-areas: ${({ sideBar }) => sideBar && '"section-title" "section-body"'};
  }
`;

export const SectionTitle = styled.div<SectionTitleProps>`
  display: grid;
  grid-template-columns: 40px auto;
  grid-area: section-title;
  font-size: 30px;
  font-weight: 500;
  grid-gap: 10px;
  align-items: center;
  border-bottom: 1px solid lightgrey;
  margin-bottom: 10px;
  
  @media (min-width: 900px) {
    grid-template-columns: ${({ sideBar }) => sideBar && '100%'}
  }

  & span {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  & .fas {
    margin-left: auto;
    margin-right: auto;

    @media (min-width: 900px) {
      ${({ sideBar }) => sideBar && css`
        display: none;
      `}
    }
  }
`;

export const SectionBody = styled.div<SectionBodyProps>`
  grid-area: section-body;
  
  @media (max-width: 900px) {
    width: calc(100% - 45px);
  }

  ${({ isList, sideBar }) => isList && css`
    display: grid;
    grid-gap: ${sideBar ? '10px' : '20px'};
  `}
  
  @media (max-width: 900px) {
    ${({ sideBar }) => sideBar && css`
      grid-template-columns: repeat(2, calc(50% - 25px));
      grid-gap: 10px 50px;
    `}
  }
  
  @media (max-width: 500px) {
    ${({ sideBar }) => sideBar && css`
      grid-template-columns: 100%;
      grid-gap: 10px;
    `}
  }
`;

export const SectionEntryContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SectionEntryTitle = styled.div`
  font-size: 1.3em;
  font-weight: 500;
`;

export const SectionEntryPeriod = styled.div`
  color: #555;
  font-size: 0.85em;
  margin-bottom: 10px;
`;

export const SectionEntrySummary = styled.div`
  line-height: 1.5em;
`;
