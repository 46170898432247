import React from 'react';

import Skill from './Skill';
import { SkillsProps } from './types';
import Section from 'components/Section';

const Skills = ({ skills }: SkillsProps) => {
	return (
		<Section
			title={
				<>
					<i className="fas fa-brain"></i><span>Ferdigheter</span>
				</>
			}
			isList
			sideBar
			skills
		>
			{skills.map((skill, index) => <Skill key={index} skill={skill} />)}
		</Section>
	);
};

export default Skills;
