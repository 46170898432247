import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto 200px;
  grid-gap: 30px;
  grid-template-areas: "header header" "main sidebar" "footer footer";
  max-width: 1000px;
  width: calc(100% - 80px);
  margin: 0 auto;
  padding: 40px 0 0;

  @media (max-width: 900px) {
    grid-template-columns: 100%;
    grid-template-areas: "header" "main" "sidebar" "footer";
    margin: 0 40px;
    padding: 40px 0 0;
  }

  @media (max-width: 500px) {
    width: calc(100% - 40px);
    margin: 0 20px;
    padding: 20px 0 0;
  }
`;

export const Main = styled.div`
  grid-area: main;
  display: grid;
  grid-gap: 30px;
`;

export const AdditionalInfo = styled.div`
  display: flex;
  grid-area: sidebar;
  flex-direction: column;
  gap: 30px;
`;
