// import React from 'react';
import { TCharacteristic, TResume } from './types';
import { getAge } from './age';
import { skills } from './skills';
import { aboutLinks, portfolioSite } from './links';

const about = {
	intro: 'Hei. Mitt navn Daniel Sandberg',
	description: `Jeg er en ${getAge()} år gammel kar fra Melhus i Trøndelag. For tiden jobber jeg som webutvikler i Tise AS, eksternt fra Trondheim. På fritiden driver jeg blant annet med dataspill, musikk og boklesing.`
};

// const accomplishments = [
//   {
//     title: '1 of 3 Winners of the 16th Monthly Coding Challenge',
//     entry: 'My personal portfolio website, WebDSandberg.com',
//     entryLink: 'webdsandberg.com',
//     entryUrl: 'https://webdsandberg.com/v2',
//     repo: false,
//     bestower: <span><a href='https://github.com/aneagoie' target='_blank' rel='noopener noreferrer'>Andrei Neagoie</a> and representatives from <a href='https://www.creative-tim.com/' target='_blank' rel='noopener noreferrer'>Creative Tim</a></span>,
//     description: <span>The challenge was to build a beautiful single-page website using modern design techniques and on of the free templates from Creative Tim. Included in the challenge were also 3 resources the contestants were supposed to use. I chose to use an existing portfolio website that I had, which already used one of the free templates, and give it a serious facelift. For a more detailed description of the challenge, checkout <a href='https://github.com/zero-to-mastery/coding_challenge-16' target='_blank' rel='noopener noreferrer'>github.com/zero-to-mastery/coding_challenge-16</a>.</span>
//   }
// ];

const education = [
	{
		studyProgram: 'Sivilingeniør i Datateknologi',
		school: 'Norges teknisk-naturvitenskapelige universitet (NTNU)',
		startDate: 'August 2015',
		graduationDate: 'Juni 2020',
		summary: `I juni 2020 fullførte jeg mastergraden min i datateknologi ved NTNU, med spesialisering i programvareutvikling. I min tid ved universitetet fikk jeg god opplæring i programvareutvikling, programvarearkitektur, samarbeid i tverrfaglige team, grunnleggende algoritmer for kunstig intelligens og en god del andre temaer og konsepter.
    
    I tillegg til sterk faglig opplæring, gjennomførte jeg også flere prosjekter, som en kreativ digital fortelling av værforhold i samarbeid med Adressa og Adressaparken, og en applikasjon for fortolling av varer ved svenskegrense med fokus på brukervennlighet og integrasjon med fremtidig teknologi.`
	},
	{
		studyProgram: 'Ettårsstudie i lederskap',
		school: 'Bildøy bibelskole, Bergen',
		startDate: 'August 2013',
		graduationDate: 'Juni 2014',
		summary: 'Jeg har studert ett år med lederskap ved Bildøy bibelskole, litt utenfor Bergen. Gjennom året fikk jeg noen ukers opplæring i ulike lederskapstema sprett utover studieåret, mens jeg resten av tiden jobbet som ettåring i Indremisjonsforbundets menighet Betlehem i Bergen, der jeg anvendte det jeg lærte på skolen. Jeg fikk god erfaring med å være del av flere lederteam for ungdomsarbeid, samt hovedansvar for organisering av lovsangsteamene i studentarbeidet i menigheten.'
	},
	{
		studyProgram: 'Ettårsstudie i bibel og musikk',
		school: 'Bildøy bibelskole, Bergen',
		startDate: 'August 2012',
		graduationDate: 'Juni 2013',
		summary: `Skoleåret 2012-2013 studerte jeg bibel og musikk ved Bildøy bibelskole, litt utenfor Bergen. Den ene halvdelen av studiet var fokusert på musikk, der jeg lærte om musikkhistorie og musikkteori, med mer, fikk mentoring i trommer og akustisk gitar, pg fikk brukt mine musikalske ferdigheter på huskonserter på skolen og til innspilling av plater.
    
    Den andre delen av studiet bestod av bibelstudier, med god opplæring i både det gamle og det nye testamentet, samt dyptgående undervisning i utvalgte deler av bibelen. I tillegg fikk jeg også litt erfaring i teamarbeid, både lokalt på skolen og i små oppdrag i andre menigheter i Bergensområdet.`
	}
];

const employmentHistory = [
	{
		employer: 'Tise AS',
		position: 'Webutvikler (heltid)',
		startDate: '1. juli 2020',
		endDate: 'Nå',
		summary: `Etter jeg ble ferdig med studiet mitt ved NTNU i juni 2020, begynte jeg å jobbe heltid hos Tise i juli 2020. Jeg fortsatte å jobbe fra Trondheim, siden pandemien på det tidspunktet var i full sving, og jobber fortsatt fra Trondheim den dag i dag. Arbeidoppgavene har blitt mer mangfoldige siden sommerjobben og deltidsjobben. I dag jobber jeg med utvikling av ny egenskaper, vedlikehold og bugfiksing av eksisterende funksjoner, oppdatering av bilbioteker som brukes i utviklingen av nettsiden, og kodegjennomgang av andres utvikling.
  
    
    I tillegg til utvikling av nettsiden er jeg del av en roterende on-call ordning som sørger for at Tise er oppe og går. Om det skulle gå av alarmer under mitt skift undersøker jeg hva som er galt, og fikser det om mulig eller eskalerer det til en med autoritet og/eller kunnskap til å fikse problemet.
    
    Ved siden av selve Tise har jeg også hjulpet litt med bugfiksing i nettbutikken vår, Tise Basics, en nettbutikk som er bygget i Wordpress.`
	},
	{
		employer: 'Tise AS',
		position: 'Webutvikler (deltid)',
		startDate: 'August 2019',
		endDate: '30. juni 2020',
		summary: 'Skoleåret 2019-2020 fortsatte jeg å jobbe deltid hos Tise, fra Trondheim, mens jeg fullførte det siste studieåret på NTNU. Jeg jobbet mer og mer med sentrale elementer på nettsiden, i tillegg til viktige oppgaver som innføring av TypeScript i kodebasen.'
	},
	{
		employer: 'Tise AS',
		position: 'Sommerintern webutvikler',
		startDate: 'Juni 2019',
		endDate: 'August 2019',
		summary: `Sommeren 2019 var jeg så heldig å få jobbe hos Tise AS. Jobbet bestod i første rekke i å implementere et internt verktøy for administratorer på nettsiden som er bygget i ReactJS, så de kunne gruppere ulike brukere på spesifikke måter. Da denne oppgaven var ferdig tidligere enn planlagt, ble jeg raskt dratt inn i utviklingen av hele nettsiden, og fikk jobbet med ulike deler av den gjennom sommeren.

    Den første uken var bodde jeg på hotell i Oslo mens jeg fikk innføring i applikasjonen, utviklingskulturen og bedriften generelt. De resterende 7 ukene jobbet jeg ekstrent fra Trondheim.`
	},
	{
		employer: 'Visma International AS',
		position: 'Sommerjobb',
		startDate: '18. juni 2018',
		endDate: '7. august 2018',
		summary: `I sommerjobben min hos Visma jobbet jeg med automatisk e2e-testing av en AngularJS-applikasjon. Oppgaven min var å skrive automatiske tester i testingsrammeverket Protractor for ulike scenarier i brukergrensesnittet som til da hadde blitt gjort manuelt av teamets QA-ansvarlig.
    
    Gjennom sommerjobben fikk jeg god erfaring i automatisk testing av brukergrensesnitt, samtidig som jeg lærte meg JavaScript mens jeg jobbet da jeg ikke kunne det fra før, hvilket jeg opplever som utrolig verdifull erfaring og har tatt med meg videre.`
	},
	// {
	//   employer: 'Norstat Trondheim',
	//   position: 'Phone Interviewer',
	//   startDate: 'October 19th 2016',
	//   endDate: 'January 6th 2017',
	//   summary: 'My work as a phone interviewer consisted of asking people questions regarding advertising, politics, store sales, and various other subjects.'
	// },
	// {
	//   employer: 'Vivo Trondheim',
	//   position: 'Part-time Employee',
	//   startDate: 'November 1st 2014',
	//   endDate: 'August 2nd 2015',
	//   summary: 'As a part-time employee at the bookstore Vivo Trondheim, my responsibilities were customer service, sales and shelving of books, receipt of goods, and basic cleaning of the store.'
	// },
	// {
	//   employer: 'Bildøy Bible College, Bergen',
	//   position: 'PR-Employee',
	//   startDate: 'July 1st 2014',
	//   endDate: 'August 10th 2014',
	//   summary: 'During my summer job as PR-employee for Bildøy Bible College, I toured 5 different summer youth conventions and advertised on behalf of the school, talking to the convention participants about my experience from and their opportunities at the school.'
	// },
	// {
	//   employer: 'Betlehem, Bergen IM',
	//   position: 'Trainee',
	//   startDate: 'August 12th 2013',
	//   endDate: 'June 13th 2014',
	//   summary: 'As part of my year as a trainee at Betlehem, Bergen IM (a congregation in Bergen, Norway), I worked as part of the leadership team for the youth and student work, both within the congregation itself and another congregation connected to it.'
	// }
	// TODO: addd an 'other' option for non-relevant jobs
];

const identifiers = 'Nerd, kristen, programvareutvikler, gamer, musiker';

const profileName = 'Daniel Sandberg';

const profileTitle = 'Programvareutvikler';

const hobbies = ['Dataspill', 'Lesing', 'Musikk', 'Tegning'];

const characteristics: TCharacteristic[] = [
	{
		title: 'Lærenem',
		description: '',
	},
	{
		title: 'Nysgjerrig',
		description: '',
	},
	{
		title: 'Allsidig',
		description: '',
	},
	{
		title: 'Omgjengelig',
		description: '',
	},
	{
		title: 'Tilpasningsdyktig',
		description: '',
	},
];

const resume: TResume = {
	about,
	aboutLinks,
	// accomplishments,
	characteristics,
	education,
	employmentHistory,
	hobbies,
	identifiers,
	portfolioSite,
	profileName,
	profileTitle,
	skills,
};

export default resume;
