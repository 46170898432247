import React, { ReactElement } from 'react';

import { EmploymentHistoryProps } from './types';
import Employment from './Employment';
import { TEmployment } from './Employment/types';
import Section from 'components/Section';

const EmploymentHistory = ({ employmentHistory }: EmploymentHistoryProps): ReactElement => {
	return (
		<Section
			title={
				<>
					<i className="fas fa-building" />
					<span>Erfaring</span>
				</>
			}
			isList
		>
			{employmentHistory.map((employment: TEmployment, index: number) => <Employment key={index} employment={employment}/>)}
		</Section>
	);
};

export default EmploymentHistory;
