import styled, { css } from 'styled-components';

export const SkillContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: auto;
  grid-template-areas: "skill-label" "skill-level";
  grid-gap: 5px;
  box-sizing: border-box;

  @media (max-width: 500px) {
    width: 100%;
    margin-right: auto;
  }
`;

export const SkillLabel = styled.div`
  grid-area: skill-label;
  text-align: left;
  font-size: 18px;
  box-sizing: border-box;
`;

export const SkillBarContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  grid-area: skill-level;
  background-color: #E9EDF0;
`;

export const SkillBar = styled.div<{ skillLevel: number }>`
  display: block;
  width: 0;
  height: 100%;
  margin-left: 0;
  margin-right: auto;
  border-radius: 2px;
  z-index: 10;
  box-sizing: border-box;

  ${({ skillLevel }) => css`
    ${skillLevel === 1 && css`
      width: 25%;
      background-color: rgb(255, 165, 0);
    `}

    ${skillLevel === 2 && css`
      width: 50%;
      background-color: rgb(255, 255, 0);
    `}

    ${skillLevel === 3 && css`
      width: 75%;
      background-color: rgb(140, 255, 47);
    `}

    ${skillLevel === 4 && css`
      width: 100%;
      background-color: rgb(0, 180, 0);
    `}
  `}
`;
